import React from 'react'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

import Layout from '../../../components/layout'
import Nav from '../../../components/nav'
import SEO from '../../../components/seo'
import '../../../styles/index.scss'
import OptBtn from '../../../components/button'

const IndexPage = () => {
    return (
        <Layout lang="es">
            <SEO
                title="Optiwe - Integración con Zoho"
                description="Integra Optiwe con Zoho para centralizar tu base de clientes y
                 manejar eficientemente tus clientes potenciales."
                lang="es"
                locales={[
                    {
                        hreflang: 'es',
                        href: 'https://optiwe.com/es/integraciones/zoho/'
                    }
                ]}
            />

            <main className="container-fluid ow-banner">
                {/*
                ^*********
                * HEADER *
                **********
                */}
                <Nav
                    lang="es"
                    showButtons
                />

                {/*
                ***************
                * MAIN BANNER *
                ***************
                * TODO: If the banner is used in another view, create a <Banner /> component
                */}
                <div className="container ow-banner__content">
                    <div className="row">
                        <div className="col-xl-12 col-xxl-12 ow-banner__home-content">
                            <h1 className="ow-section--cards__title">
                                <span className="bold">
                                    Integra Optiwe con <strong>Zoho</strong>
                                </span>
                            </h1>
                            <p className="ow-section--cards__desc">
                                Optiwe es un CRM para los canales digitales como Whatsapp,
                                Instagram y Facebook. Una pregunta que usualmente se hacen
                                nuestros clientes es, uso Zoho como sistema de ticketing,
                                ¿Puedo integrarlo a Optiwe? La respuesta es sí.
                            </p>
                            <p className="ow-section--cards__desc">
                                Integrar Optiwe con un sistema de ticketing tiene varias ventajas
                                importantes:
                            </p>
                            <ul>
                                <li>
                                    <p className="ow-section--cards__desc">
                                        Mejora la eficiencia del servicio al cliente: al integrar
                                        los dos sistemas, los agentes de servicio al cliente pueden
                                        ver el historial de comunicación y los detalles de contacto
                                        de los clientes directamente en el sistema de ticketing.
                                        Esto les permite brindar una respuesta más rápida
                                        y personalizada, lo que puede aumentar la satisfacción del
                                        cliente.
                                    </p>
                                </li>
                                <li>
                                    <p className="ow-section--cards__desc">
                                        Optimiza la gestión de casos: la integración de un sistema
                                        de ticketing y un CRM permite que los casos se asignen
                                        automáticamente a los agentes de servicio al cliente
                                        adecuados. Los casos también se pueden priorizar según su
                                        importancia, lo que garantiza que los problemas críticos se
                                        aborden primero.
                                    </p>
                                </li>
                                <li>
                                    <p className="ow-section--cards__desc">
                                        Ofrece una vista completa del cliente: al integrar los dos
                                        sistemas, se puede acceder a toda la información del cliente
                                        en un solo lugar, lo que ayuda a los agentes de servicio al
                                        cliente a comprender mejor las necesidades del cliente y a
                                        ofrecer soluciones personalizadas. Además, esto también
                                        ayuda a los equipos de ventas y marketing a tener una
                                        visión más completa del cliente para poder crear campañas
                                        más efectivas.
                                    </p>
                                </li>
                                <li>
                                    <p className="ow-section--cards__desc">
                                        Mejora el seguimiento y la medición de las métricas de
                                        servicio al cliente: la integración de un sistema de
                                        ticketing y un CRM permite el seguimiento de las métricas
                                        de servicio al cliente, como el tiempo de respuesta y la
                                        satisfacción del cliente. Esto permite a las empresas
                                        realizar un seguimiento de su rendimiento y hacer ajustes
                                        en consecuencia.
                                    </p>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="mt-5 mb-5"
                        style={{ display: 'flex', justifyContent: 'center' }}
                    >
                        <OptBtn isLink rounded small href="/es/crear-cuenta/">
                            Regístrate, es grátis!
                        </OptBtn>
                    </div>
                </div>
            </main>
        </Layout>
    )
}

export default IndexPage
